import axios from "axios";

var url =  `${process.env.REACT_APP_PROD_URL}/api/v1/`;
const AxiosAnonymWebService =
    axios.create({
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: (10 * 1000)
    })


    

export default AxiosAnonymWebService;

