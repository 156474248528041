import moment from 'moment';
import * as React from 'react';
import AxiosAnonymWebService from '../axiosConfig/axiosAnonymWebservice';
import { IOrderOfficeRequest } from '../Interface/IOrderOfficeRequest';
import './OrderOfficeReinigung.css';

interface IOrderOfficeReinigung {
}
const OrderOfficeReinigung: React.FunctionComponent<IOrderOfficeReinigung> = (props) => {
    const [orderOfficeRequest, setOrderOfficeRequest] = React.useState<IOrderOfficeRequest>({
        bereichId: -1,
        objektArtId: -1,
        objektId: -1,
        orderCreatingDate: -1,
        unterbereichId: -1
    });

    const [isSuccessfull, setIsSuccessfull] = React.useState<boolean>();
    const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>();
    const [apiKey, setApiKey] = React.useState<string>();
    const queryparams = React.useMemo(() =>
        new URLSearchParams(window.location.search), []
    );


    React.useLayoutEffect(() => {
        var bereichString = queryparams.get("bereichId") ?? -1;
        var unterbreichString = queryparams.get("unterbereichId") ?? -1;
        var objektString = queryparams.get("objektId") ?? -1;
        var objektArtString = queryparams.get("objektArtId") ?? -1;
        var data: IOrderOfficeRequest = {
            bereichId: +bereichString,
            objektArtId: +objektArtString,
            objektId: +objektString,
            unterbereichId: +unterbreichString,
            orderCreatingDate: 0
        }
        setOrderOfficeRequest(data);
        setApiKey(queryparams.get("apikey") ?? "")
    },[queryparams])

    const onClickHandler = React.useCallback(async() => {
        var data: IOrderOfficeRequest = {
            ...orderOfficeRequest,
            orderCreatingDate: moment().unix()
        }
        try
        {
            const result = await AxiosAnonymWebService.post("OrderOffice", data, {
                headers: {
                    "x-api-key": apiKey
                }
            });
            setError(false);
            setIsSuccessfull(true);
            setIsButtonDisabled(true);
        }
        catch (err)
        {
            setIsSuccessfull(false);
            setError(true);
            setIsButtonDisabled(false);
        }
    }, [orderOfficeRequest, apiKey])

    return (
        <div className='flexbox-panel'>
            {(isSuccessfull && !error) &&
                <div className='flexbox'>
                    Reinigung wurde erfolgreich beauftragt!
                </div>
            }
           
            {(!isButtonDisabled && !isSuccessfull) &&
                <>
                    <div className='flexbox'>
                        <b>Reinigung für Büro aufgeben</b>
                    </div>
                    <button className='flexbox button' onClick={onClickHandler}>Reinigung aufgeben</button>
                </>
            }
             {(!isSuccessfull && error) &&
                <div className='flexbox error'>
                    Es ist ein Fehler aufgetreten! Versuchen Sie es nochmal!
                </div>
            }
        </div>
    )
}
export default OrderOfficeReinigung;